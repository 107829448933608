<template>
  <b-card no-body class="card-statistics">
    <b-card-header>
      <b-card-title>Reporte de ventas por categorias</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        Última actualización {{ Date.now() | date }}
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col class="d-flex align-items-center justify-content-center"> 
          <label for="frequency">Seleccionar un periodo actual:</label>
          <select id="frequency" v-model="selectedFrequency" @change="fetchData">
            <option value="daily">Día</option>
            <option value="weekly">Semana</option>
            <option value="monthly">Mes</option>
            <option value="yearly">Año en curso</option>
            <option value="all">Desde el inicio</option>
            <option value="custom" disabled>
              Personalizado
            </option>
          </select>
        </b-col>
        <b-col class="d-flex align-items-center justify-content-center">
          <label for="range-picker">Seleccionar un rango de fechas:</label>
          <feather-icon
            icon="CalendarIcon"
            size="16"
          />
          <flat-pickr
            v-model="rangePicker"
            :config="{ mode: 'range'}"
            class="form-control bg-transparent border-0 shadow-none"
            placeholder="YYYY-MM-DD"
            @on-change="fetchDataByRange()"
          />   
        </b-col>
      </b-row>
      <b-card>
        <b-row>
          <b-col>
            <div v-if="selectedFrequency == 'daily' || selectedFrequency == 'custom'">
              <b-table
                class="position-relative"
                :items="formattedColumns"
                :fields="tableColumns"
                primary-key="id"
                show-empty
                responsive
                empty-text="No existen registros aún"
              >
                <template #cell(category)="data">
                  <span class="text-capitalize text-nowrap text-primary">
                    {{ data.value }}
                  </span>
                </template>
                <template #cell(with_tax)="data">
                  <b-badge 
                    pill
                    :variant="(data.value === 0) ? 'danger' : 'success'"
                  >
                    <span>
                      {{
                        new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(data.value)
                      }}
                    </span>
                  </b-badge>
                </template>
                <template #cell(without_tax)="data">
                  <b-badge 
                    pill
                    :variant="(data.value === 0) ? 'danger' : 'success'"
                  >
                    <span>
                      {{
                        new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(data.value)
                      }}
                    </span>
                  </b-badge>
                </template>
              </b-table>
              <div class="mx-2 mb-2">
                <b-row>
                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">Mostrando {{ pagination.count }} de {{ pagination.total_objects }} registros</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >
                    <b-pagination
                      v-model="pagination.page"
                      :total-rows="pagination.total_objects"
                      :per-page="pagination.per_page"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item" 
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"

                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
               </div>
            </div>
            <sales-weekly-report-table 
              :categoriesReport="categoriesReport"
              :selectedFrequency="selectedFrequency"
              v-if="selectedFrequency == 'weekly'"
            />
            <sales-monthly-table-columns 
              :categoriesReport="categoriesReport"
              :selectedFrequency="selectedFrequency"
              v-else-if="selectedFrequency == 'monthly'"
            />
            <sales-yearly-table-columns 
              :categoriesReport="categoriesReport"
              :selectedFrequency="selectedFrequency"
              v-else-if="selectedFrequency == 'yearly'"
            />
            <sales-all-report-table 
              :categoriesReport="categoriesReport"
              :selectedFrequency="selectedFrequency"
              v-else-if="selectedFrequency == 'all'"
            />
          </b-col>
        </b-row>
        <!-- <b-row v-if="selectedFrequency !== 'daily'">
          <b-col cols="12" class="mx-auto" md="6">
            <div class="chart-container">
              <vue-apex-charts
                height="320"
                type="bar"
                :options="chartOptions"
                :series="chartSeries"
                class="mt-4"
              />
            </div>
          </b-col>
        </b-row> -->
      </b-card>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// import VueApexCharts from 'vue-apexcharts'
import Statistics from '@core/components/CustomerStatistics.vue'
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BTable,
} from 'bootstrap-vue'
import SalesWeeklyReportTable from './SalesWeeklyReportTable.vue'
import SalesMonthlyTableColumns from './SalesMonthlyReportTable.vue'
import SalesYearlyTableColumns from './SalesYearlyReportTable.vue'
import SalesAllReportTable from './SalesAllReportTable.vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    // VueApexCharts,
    Statistics,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BTable,
    SalesWeeklyReportTable,
    SalesMonthlyTableColumns,
    SalesYearlyTableColumns,
    SalesAllReportTable,
    flatPickr,
  },
  data() {
    return {
      selectedFrequency: 'yearly',
      rangePicker: [],
      pagination: {
        page: 1,
        per_page: 10, 
        total_objects: 0, 
        count: 0
      },
      chartOptions: {
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        legend: {
          position: 'right',
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
        xaxis: {
          type: 'category',
          categories: [],
        },
        title: {
          text: 'Información general de ventas',
          align: 'center',
          margin: 0,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize:  '14px',
            fontWeight:  'bold',
            fontFamily:  undefined,
            color:  '#d4d9d4'
          },
        },
      },
      chartSeries: [],
      tableColumns: [
        {
          key: 'category',
          label: 'Categoría',
          sortable: true,
        },
        { 
          key: 'sells_count',
          label: 'Ventas por categoría',
          sortable: true,
        },
        {
          key: 'participants_count',
          label: 'Total de participantes',
          sortable: true,
        },
        {
          key: 'invoiced_sells_count',
          label: 'Ventas facturadas',
          sortable: true,
        },
        {
          key: 'payed_sells_count',
          label: 'Ventas pagadas',
          sortable: true,
        },
        {
          key: 'with_tax',
          label: 'Total con IVA',
          sortable: true,
        },
        {
          key: "without_tax",
          label: 'Total sin IVA',
          sortable: true,
        },
      ],
    }
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  beforeMount() {
    this.fetchCategoriesReport({ storeId: this.$route.params.id, frequency: this.selectedFrequency })
    .finally(() => {
      this.pagination.total_objects = this.clientsReport.length;
    })
  },
  computed: {
    ...mapGetters('dashboard', ['categoriesReport']),
    formattedColumns(){
      this.pagination.total_objects = this.categoriesReport.length;
      if(!this.categoriesReport.length){
        return []
      }

      const startIndex = (this.pagination.page - 1) * this.pagination.per_page;
      const endIndex = startIndex + this.pagination.per_page;
      this.pagination.count = this.categoriesReport.slice(startIndex, endIndex).length;
      
      return this.categoriesReport.slice(startIndex, endIndex);
    }
  },
  methods: {
    ...mapActions('dashboard', ['fetchCategoriesReport']),
    fetchData() { 
      if(this.selectedFrequency != 'custom'){
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          },
        })
        this.fetchCategoriesReport({ storeId: this.$route.params.id, frequency: this.selectedFrequency })
        .then(response => {
          // <--- TODO: REMAKE GRAPHICS
          // if (this.selectedFrequency != 'daily') {
          //   this.updateStats(response)
          // }
        })
        .finally(() => {
          if(this.categoriesReport.length === 0) this.pagination.count = 0;
          this.$swal.close()
        })
      }
    },

    fetchDataByRange(){
      const startDate = Array.isArray(this.rangePicker) ? this.rangePicker[0] : this.rangePicker.substring(0, 10)
      const endDate = Array.isArray(this.rangePicker) ? this.rangePicker[1] : this.rangePicker.substring(13, 24)
      if(!startDate){
        return
      }
      const trimmedStartDate = startDate.replace(/ /g, '')
      const trimmedEndDate = endDate ? endDate.replace(/ /g, '') : null
      const frequency = trimmedEndDate ? `${trimmedStartDate}_${trimmedEndDate}` : `${trimmedStartDate}_${trimmedStartDate}`
      this.selectedFrequency = 'custom'
      this.fetchCategoriesReport({ storeId: this.$route.params.id, frequency: frequency })
    },
  },
    // <--- TODO: REMAKE GRAPHICS
    // updateStats(response) {
    //   const series = []
    //   const categories = []

    //   response.forEach((item) => {
    //     const category = item.category
    //     let productSeries = series.find((serie) => serie.name === category)
    //     if (!productSeries) {
    //       productSeries = {
    //         name: category,
    //         data: [],
    //       }
    //       series.push(productSeries)
    //     }
    //     item.time_line.forEach((time) => {
    //       if(!categories.includes(time.time)){
    //         categories.push(time.time)
    //       }
    //       const dataIndex = categories.indexOf(time.time)
    //       while (productSeries.data.length <= dataIndex) {
    //         productSeries.data.push(0);
    //       }

    //       productSeries.data[dataIndex] += time.count;
    //     })
    //   })
    //   this.chartOptions = {
    //     ...this.chartOptions,
    //     xaxis: {
    //       ...this.chartOptions.xaxis,
    //       categories,
    //     },
    //   }

    //   this.chartSeries = series
    // },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

  .small-title .card-header {
    font-size: 12px; /* Adjust the font size as needed */
  }
  .light-success {
    color: #00cfe8; /* Replace with the desired color value */
    font-weight: bold;
  }
  .small-subtitles .card-subtitle {
    font-size: 5px; /* Adjust the font size as needed */
  }
  .custom-span {
    font-weight: bold;
    color: #00cfe8;
  }

  .custom-span-white {
    font-weight: bold;
    color: #d4d9d4;
  }

  .chart-container {
    display: grid;
    grid-template-rows: min-content 1fr;
  }


</style>