<template>
  <div>
    <b-table
      class="position-relative"
      :items="formattedAllColumns"
      :fields="allTableColumns"
      responsive
      primary-key="id"
      show-empty
      empty-text="No existen registros aún"
    >
      <template #cell(category)="data">
        <span class="text-capitalize text-nowrap text-primary">
          {{ data.value }}
        </span>
      </template>
      <template #cell(with_tax)="data">
        <b-badge 
          pill
          :variant="(data.value === 0) ? 'danger' : 'success'"
        >
          <span>
            {{
              new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(data.value)
            }}
          </span>
        </b-badge>
      </template>
      <template #cell(without_tax)="data">
        <b-badge 
          pill
          :variant="(data.value === 0) ? 'danger' : 'success'"
        >
          <span>
            {{
              new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(data.value)
            }}
          </span>
        </b-badge>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando {{ pagination.count }} de {{ pagination.total_objects }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="pagination.page"
              :total-rows="pagination.total_objects"
              :per-page="pagination.per_page"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"

                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
    </div>
  </div>
</template>

<script>
import {
 BTable,
 BPagination,
} from 'bootstrap-vue'
import { find } from 'postcss-rtl/lib/affected-props';
import { ChevronsLeftIcon } from 'vue-feather-icons';

export default {
 props: {
    categoriesReport: {
     type: Array,
     required: true,
   },
   selectedFrequency: {
     type: String,
     required: true,
   },
 },
 components: {
   BTable,
   BPagination,
 },
 data() {
   return {
      allTableColumns: [
       {
         key: 'category',
         label: 'Categoría',
         sortable: true,
       },
       { 
         key: 'sells_count',
         label: 'Ventas por categoría',
         sortable: true,
       },
       {
         key: 'participants_count',
         label: 'Total de participantes',
         sortable: true,
       },
       {
         key: 'invoiced_sells_count',
         label: 'Ventas facturadas',
         sortable: true,
       },
       {
         key: 'payed_sells_count',
         label: 'Ventas pagadas',
         sortable: true,
       },
       {
         key: 'with_tax',
         label: 'Total con IVA',
         sortable: true,
       },
       {
         key: "without_tax",
         label: 'Total sin IVA',
         sortable: true,
       },
     ],
     pagination: {
        page: 1,
        per_page: 10, 
        total_objects: 0, 
        count: 0
      },
   }
 },
 watch: {
    categoriesReport(){
      const dynamicColumns = [];
      const propertyName = 'time_line'

      const allTimeLineKeys = this.categoriesReport.reduce((keys, item) => {
        return keys.concat(Object.keys(item[propertyName]));
      }, []);

      const uniqueProperties = new Set(allTimeLineKeys);

      Array.from(uniqueProperties).forEach(property => {
        dynamicColumns.push({
          key: `${propertyName}[${property}].count`,
          label: `${property}`,
          sortable: true,
          formatter: (value, key, item) => value || 0
        });
      });

      this.allTableColumns = [
        {
          key: 'category',
          label: 'Categoría',
          sortable: true,
        },
        ...dynamicColumns,
        { 
          key: 'sells_count',
          label: 'Ventas por categoría',
          sortable: true,
        },
        {
          key: 'participants_count',
          label: 'Total de participantes',
          sortable: true,
        },
        {
          key: 'invoiced_sells_count',
          label: 'Ventas facturadas',
          sortable: true,
        },
        {
          key: 'payed_sells_count',
          label: 'Ventas pagadas',
          sortable: true,
        },
        {
          key: 'with_tax',
          label: 'Total con IVA',
          sortable: true,
        },
        {
          key: "without_tax",
          label: 'Total sin IVA',
          sortable: true,
        },
      ];
    }
  },
 computed: {
    formattedAllColumns(){
      this.pagination.total_objects = this.categoriesReport.length;
      if (!this.categoriesReport.length) {
        return [];
      }

      const startIndex = (this.pagination.page - 1) * this.pagination.per_page;
      const endIndex = startIndex + this.pagination.per_page;
      this.pagination.count = this.categoriesReport.slice(startIndex, endIndex).length;

      return this.categoriesReport.slice(startIndex, endIndex);
    },
  },
}
</script>