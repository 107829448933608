
<template>
  <div>
    <b-table
      class="position-relative"
      :items="formattedColumns"
      :fields="monthlyTableColumns"
      responsive
      primary-key="id"
      show-empty
      empty-text="No existen registros aún"
    >
      <template #cell(category)="data">
        <span class="text-capitalize text-nowrap text-primary">
          {{ data.value }}
        </span>
      </template>
      <template #cell(with_tax)="data">
        <b-badge 
          pill
          :variant="(data.value === 0) ? 'danger' : 'success'"
        >
          <span>
            {{
              new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(data.value)
            }}
          </span>
        </b-badge>
      </template>
      <template #cell(without_tax)="data">
        <b-badge 
          pill
          :variant="(data.value === 0) ? 'danger' : 'success'"
        >
          <span>
            {{
              new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(data.value)
            }}
          </span>
        </b-badge>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Mostrando {{ pagination.count }} de {{ pagination.total_objects }} registros</span>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.total_objects"
            :per-page="pagination.per_page"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"

              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
 BTable,
} from 'bootstrap-vue'

export default {
 props: {
  categoriesReport: {
     type: Array,
     required: true,
   },
   selectedFrequency: {
     type: String,
     required: true,
   },
 },
 components: {
   BTable,
 },
 data() {
   return {
    monthlyTableColumns: [
      {
        key: 'category',
        label: 'Categoría',
        sortable: true,
      },
      {
        key: 'time_line.0.count',
        label: 'Semana 1',
        sortable: true,
      },
      {
        key: 'time_line.1.count',
        label: 'Semana 2',
        sortable: true,
      },
      {
        key: 'time_line.2.count',
        label: 'Semana 3',
        sortable: true,
      },
      {
        key: 'time_line.3.count',
        label: 'Semana 4',
        sortable: true,
      },
      {
        key: 'time_line.4.count',
        label: 'Semana 5',
        sortable: true,
      },
      { 
        key: 'sells_count',
        label: 'Ventas por categoría',
        sortable: true,
      },
      {
        key: 'participants_count',
        label: 'Total de participantes',
        sortable: true,
      },
      {
        key: 'invoiced_sells_count',
        label: 'Ventas facturadas',
        sortable: true,
      },
      {
        key: 'payed_sells_count',
        label: 'Ventas pagadas',
        sortable: true,
      },
      {
        key: 'with_tax',
        label: 'Total con IVA',
        sortable: true,
      },
      {
        key: "without_tax",
        label: 'Total sin IVA',
        sortable: true,
      },
    ],
    pagination: {
      page: 1,
      per_page: 10, 
      total_objects: 0, 
      count: 0
    },
   }
 },
 watch: {
    categoriesReport(){
      this.pagination.total_objects = this.categoriesReport.length;
      const allWeeks = new Set();

      this.categoriesReport.forEach(item => {
        item.time_line.forEach(weekObj => {
          allWeeks.add(weekObj.time);
        });
      });

      const weekDaysMap = {
        "Semana_1": 0,
        "Semana_2": 1,
        "Semana_3": 2,
        "Semana_4": 3,
        "Semana_5": 4,
      };

      const dynamicColumns = Array.from(allWeeks).map((week) => ({
        key: `time_line.${weekDaysMap[week]}.count`,
        label: `${week}`.replace('_', ' '),
        sortable: true,
      }));  

      this.monthlyTableColumns = [
        {
          key: 'category',
          label: 'Categoría',
          sortable: true,
        },
        ...dynamicColumns,
        { 
          key: 'sells_count',
          label: 'Ventas por categoría',
          sortable: true,
        },
        {
          key: 'participants_count',
          label: 'Total de participantes',
          sortable: true,
        },
        {
          key: 'invoiced_sells_count',
          label: 'Ventas facturadas',
          sortable: true,
        },
        {
          key: 'payed_sells_count',
          label: 'Ventas pagadas',
          sortable: true,
        },
        {
          key: 'with_tax',
          label: 'Total con IVA',
          sortable: true,
        },
        {
          key: "without_tax",
          label: 'Total sin IVA',
          sortable: true,
        },
      ];
    }
  },
 computed: {
  formattedColumns(){
      if (!this.categoriesReport.length) {
        this.$swal.close()
        return []
      }

      const startIndex = (this.pagination.page - 1) * this.pagination.per_page;
      const endIndex = startIndex + this.pagination.per_page;
      this.pagination.count = this.categoriesReport.slice(startIndex, endIndex).length;

      return this.categoriesReport.slice(startIndex, endIndex);
    }
 }
}
</script>